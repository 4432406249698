import React, { useState } from 'react';
import Button from '../button/Button';
import './question.css';

const Teams = {
  FIRST_TEAM: 1,
  SECOND_TEAM: 2,
};

const convertToWei = (amount) => {
  return amount * (10 ** 18);
};

const convertToBNB = (amount) => {
  return amount / (10 ** 18);
}

export default function Question(props) {
  const [amount, setAmount] = useState(0);
  const [hasBetted, setHasBetted] = useState(null);
  const [hasEnded, setHasEnded] = useState(null);
  const [winTeam, setWinTeam] = useState(null);
  const [rewardClaimed, setRewardClaimed] = useState(null);
  const [playerWon, setPlayerWon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firstTeamBetAmount, setFirstTeamBetAmount] = useState(0);
  const [secondTeamBetAmount, setSecondTeamBetAmount] = useState(0);

  const contract = props.web3 && new props.web3.eth.Contract(
    props.contractABI,
    props.contractAddress,
  );

  const optionPicked = (callback) => {
    callback(amount);
  };

  const updateBettingStatus = () => {
    contract.methods.getHasEnded().call().then((result) => {
      setHasEnded(result);
    });

    contract.methods.getPlayerBetted(props.selectedAccount).call().then((result) => {
      setHasBetted(result);
    });

    contract.methods.getWinTeam().call().then((result) => {
      setWinTeam(result);
    });

    contract.methods.getPlayerWon(props.selectedAccount).call().then((result) => {
      setPlayerWon(result);
    });

    contract.methods.getPlayerClaimedReward(props.selectedAccount).call().then((result) => {
      setRewardClaimed(result);
    });

    contract.methods.getTotalBetOne().call().then((result) => {
      setFirstTeamBetAmount(result);
    });

    contract.methods.getTotalBetTwo().call().then((result) => {
      setSecondTeamBetAmount(result);
    });
  };

  useState(() => {
    updateBettingStatus();
  }, []);

  useState(() => {
    updateBettingStatus();
  }, [props.balance]);

  const optionClicked = (team) => {
    setIsLoading(true);
    try {
      contract.methods.bet(team).send({
        from: props.selectedAccount,
        value: convertToWei(amount)
      }).then(() => {
        updateBettingStatus();
        if (props.extraCallback) {
          props.extraCallback();
        }
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }

  const claimReward = () => {
    setIsLoading(true);
    try {
      contract.methods.claimReward().send({ from: props.selectedAccount }).then(() => {
        updateBettingStatus();
        if (props.extraCallback) {
          props.extraCallback();
        }
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getButtonJSX = () => {
    if (hasEnded == null) {
      return null;
    }
    if (hasEnded) {
      return (
        <>
          <p>
            Bet has ended. <span className={playerWon ? 'text-green-gradient' : 'text-red-gradient'}>
              You {
                playerWon 
                  ? 'won'
                  : 'lost'
              }!
            </span>
          </p>
          {
            playerWon &&
              <>
                {
                  isLoading &&
                    <p>Loading, please wait...</p>
                }
                <Button onClick={claimReward} disabled={rewardClaimed || isLoading}>
                  {
                    rewardClaimed
                      ? 'Reward already claimed'
                      : 'Claim reward'
                  }
                </Button>
              </>
          }
        </>
      );
    }
    if (hasBetted) {
      return (
        <p>
          You already bet on this one! Check back later to see if you won or lost.
        </p>
      );
    }
    return (
      <>
        <input type='number' className='question-input' onChange={(event) => setAmount(event.target.value)} placeholder='Amount to bet (in BNB)' />
        {
          isLoading &&
            <p>Loading, please wait...</p>
        }
        <div className='buttons-container'>
          <Button disabled={props.web3 == null || isLoading} onClick={() => {
            optionClicked(Teams.FIRST_TEAM);
          }} style={{ marginRight: 18 }}>
            {props.firstTeamAnswer}
          </Button>
          <Button disabled={props.web3 == null || isLoading} onClick={() => {
            optionClicked(Teams.SECOND_TEAM)
          }}>
            {props.secondTeamAnswer}
          </Button>
        </div>
      </>
    )
  };

  return (
    <div className='question-container'>
      <h2 className='question-title'>
        {props.title}
      </h2>
      {getButtonJSX()}
      <p>
        Bet contract URL: <strong><a href={`https://bscscan.com/address/${props.contractAddress}`}>{props.contractAddress}</a></strong>
      </p>
      <div>
        <p>Total bet <b>YES</b>: <strong>{convertToBNB(firstTeamBetAmount)} BNB</strong></p>
        <p>Total bet <b>NO</b>: <strong>{convertToBNB(secondTeamBetAmount)} BNB</strong></p>
      </div>
    </div>
  );
}