import logo from './logo.svg';
import Index from './pages/index/Index';

function App() {
  return (
    <Index />
  );
}

export default App;
