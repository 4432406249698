import React, { useEffect, useCallback, useState } from 'react';
import './index.css';
import Button from '../../components/button/Button';
import Web3 from 'web3';
import Question from '../../components/question/Question';
import { CONTRACT_ABI } from '../../components/abis/abi';

const Questions = [
  {
    title: 'Will FutureCoin launch any project in the next 12 months?',
    firstTeamAnswer: 'Yes',
    secondTeamAnswer: 'No',
    contractAddress: '0xcb1A37C7038ba82D944dFd1E0aeE0813f1Dff6B3',
    contractABI: CONTRACT_ABI,
  },
  {
    title: 'Will FutureCoin enlist on any exchange in the next 3 months?',
    firstTeamAnswer: 'Yes',
    secondTeamAnswer: 'No',
    contractAddress: '0xda53B3752ba9F8E2Cde03da6BF889D8CdE0CD6a1',
    contractABI: CONTRACT_ABI,
  },
  {
    title: 'Will FutureCoin reach $2/FUTURE in the next 2 years?',
    firstTeamAnswer: 'Yes',
    secondTeamAnswer: 'No',
    contractAddress: '0x2246497C825D58845B82Ab6Ebaf4f2B15aD0a225',
    contractABI: CONTRACT_ABI,
  },
  {
    title: 'Will FutureCoin launch TRADELINK (our blockchain) in the next 2 years?',
    firstTeamAnswer: 'Yes',
    secondTeamAnswer: 'No',
    contractAddress: '0xD55480f9Ff9f29740CD66046Cb67636A9321f576',
    contractABI: CONTRACT_ABI,
  },
];

export default function Index() {
  const [web3, setWeb3] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [balance, setBalance] = useState(null);

  const connectWallet = () => {
    let provider = window.ethereum;

    if (typeof provider !== 'undefined') {
      // MetaMask is installed
      const web3Provider = new Web3(provider);

      provider.request({ method: 'eth_requestAccounts' })
              .then((accounts) => { 
                setSelectedAccount(accounts[0]);
                updateBalance(web3Provider, accounts[0]);
              })
              .catch((err) => { 
                console.error(err);
              });
      
      window.ethereum.on('accountsChanged', (accounts) => {
        setSelectedAccount(accounts[0]);
        updateBalance(web3Provider, accounts[0]);
      });

      setWeb3(web3Provider);
    }
  };

  const updateBalance = (web3Provider, account) => {
    const web3Local = web3Provider || web3 || null;
    web3Local.eth.getBalance(account || selectedAccount, (err, result) => {
      if (err) {
        console.log(err)
      } else {
        setBalance(web3Local.utils.fromWei(result, "ether"))
      }
    }) 
  }

  const isWalletConnected = () => {
    return web3 !== null;
  };

  const getQuestionsJSX = () => {
    return (
      <>
        {
          Questions.map((question, index) => {
            return (
              <div key={`question-${index}-${selectedAccount}`}>
                <hr />
                <Question
                  key={`question-${index}`}
                  web3={web3}
                  title={question.title}
                  firstTeamAnswer={question.firstTeamAnswer}
                  secondTeamAnswer={question.secondTeamAnswer}
                  contractABI={question.contractABI}
                  contractAddress={question.contractAddress}
                  selectedAccount={selectedAccount}
                  extraCallback={updateBalance}
                  balance={balance}
                />
              </div>
            );
          })
        }
      </>
    );
  }

  console.log(balance);

  return (
    <div className='container'>
      <div className='container-inner'>
        {
          !isWalletConnected()
            ? <Button onClick={connectWallet}>
              Connect Wallet
            </Button>
            : <div className='logged-in'>
              <span>Conneted as <strong>{selectedAccount}</strong></span>
              <span>Balance: <strong>{(Math.round(balance * 1000000) / 1000000).toFixed(6) + ' BNB'}</strong></span>
            </div>
        }
        <div className='description'>
          <h2>Welcome!</h2>
          <p>We created this to give the people who don't believe in FutureCoin the ability to short us.</p>
          <p>Connect your wallet (<strong>MetaMask</strong>) to see the bets and the pots.</p>
          <p>Make sure you're conneted to Binance Smart Chain.</p>
          <p>Place the BNB amount you want to bet in the inputs below. Then pick <span className='text-green-gradient'>YES</span> or <span className='text-red-gradient'>NO</span>, and click the button.</p>
          <p>If you're using a mobile device, make sure you connect from the MetaMask browser.</p>
          <p>Minimum bet is <strong>0.01 BNB</strong>.</p>
        </div>
        <div className='question-list-container'>
          {
            web3 && selectedAccount && balance &&
            <>
              <h2>Available bets</h2>
              {getQuestionsJSX()}
            </>
          }
        </div>
      </div>
    </div>
  );
}